<template>
  <vs-chip
    class="chip-element"
    v-if="status"
    :title="$t(`enums.civilCaseStatuses.${status.key}`)"
    :color="status.color"
  />
</template>

<script>
import caseStatuses from "@/data/civilCases/civilCaseStatuses";

const NEW_CIVIL_CASE_STATUS_ID = "kliWkDa86XtU5rY9v82A"; //ID de estado de caso civil "nuevo"

export default {
  name: "CellRendererCivilStatus",
  computed: {
    status() {
      let id =
        this.params && this.params.value
          ? this.params.value.id
          : NEW_CIVIL_CASE_STATUS_ID;
      return caseStatuses.find((s) => s.id === id);
    },
  },
};
</script>

<style lang="scss" scpoped>
.chip-element-container {
  text-align: right;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
}

.chip-element-text {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chip-element {
  float: right;
  transform: translateY(10px);
  margin-left: 10px;
  height: 10px;
}
</style>

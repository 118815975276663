<template>
  <div>
    <vs-sidebar
      v-if="sidebarConfig"
      click-not-close
      position-right
      parent="body"
      default-index="1"
      color="primary"
      class="penal-reatail-sidebar items-no-padding"
      spacer
      v-model="sidebarConfig"
    >
      <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ $t(sidebarConfig.title) }}</h4>
        <feather-icon
          icon="XIcon"
          @click.stop="sidebarConfig = null"
          class="cursor-pointer"
        ></feather-icon>
      </div>
      <vs-divider class="mb-0"></vs-divider>

      <VuePerfectScrollbar
        class="scroll-area--data-list-add-new"
        :settings="{ maxScrollbarLength: 60, wheelSpeed: 0.6 }"
        :key="$vs.rtl"
      >
        <div class="p-6">
          <div
            class="vx-col w-full mb-2"
            :class="`sm:w-1/1`"
            v-for="(f, p) in sidebarConfig.attributes"
            v-bind:key="`field_${p}`"
            v-if="!f.visibility || f.visibility(sidebarConfig.model)"
          >
            <label
              v-if="!!f.attribute"
              class="vs-input--label"
              :class="{
                'text-danger': sidebarConfig.modifications.hasOwnProperty(
                  f.attribute
                ),
              }"
            >
              {{ $t(`${sidebarConfig.collectionName}.fields.${f.attribute}`) }}
            </label>
            <br />
            <vs-input
              v-if="f.type === 'input'"
              class="w-full"
              v-model="sidebarConfig.model[f.attribute]"
              :name="f.attribute"
              v-validate="f.validation"
              :type="f.inputType || 'text'"
              :data-vv-as="
                $t(`${sidebarConfig.collectionName}.fields.${f.attribute}`)
              "
            />
            <vs-textarea
              v-else-if="f.type === 'text'"
              class="w-full"
              v-model="sidebarConfig.model[f.attribute]"
              :name="f.attribute"
              v-validate="f.validation"
              :data-vv-as="
                $t(`${sidebarConfig.collectionName}.fields.${f.attribute}`)
              "
            />
            <sketch-picker
              v-else-if="f.type === 'color'"
              :value="sidebarConfig.model[f.attribute] || 'rgb(115, 103, 240)'"
              @input="(e) => updateColor(e, f)"
              :name="f.attribute"
              v-validate="f.validation"
              :data-vv-as="
                $t(`${sidebarConfig.collectionName}.fields.${f.attribute}`)
              "
            />
            <flat-pickr
              v-else-if="f.type === 'datetime'"
              v-model="sidebarConfig.model[f.attribute]"
              :config="f.extra.config"
              class="flatpickr-input"
              :name="f.attribute"
              :data-vv-as="
                $t(`${sidebarConfig.collectionName}.fields.${f.attribute}`)
              "
              :v-validate="f.validation"
            />
            <v-select
              v-else-if="f.type === 'enum'"
              label="alias"
              :reduce="
                (option) =>
                  f.valueFormatter
                    ? f.valueFormatter(option)
                    : { id: option.id, key: option.key, alias: option.alias }
              "
              :options="
                (f.extra.optionsFunction
                  ? f.extra.optionsFunction(sidebarConfig.model)
                  : f.extra.options
                )
                  .map((x) => ({
                    id: x.id,
                    key: x.key,
                    alias: $t(`enums.${f.extra.enumType}.${x.key}`),
                  }))
                  .sort((a, b) => {
                    return f.extra.customSort
                      ? f.extra.customSort(a, b)
                      : a.alias.localeCompare(b.alias);
                  })
              "
              :getOptionLabel="(o) => getOptionLabel(o, f)"
              :clearable="!f.validation || f.validation.indexOf('required') < 0"
              v-model="sidebarConfig.model[f.attribute]"
              class="mb-4 md:mb-0"
              :v-validate="f.validation"
              :name="f.attribute"
              :filterBy="selectFilter"
              :data-vv-as="
                $t(`${sidebarConfig.collectionName}.fields.${f.attribute}`)
              "
            />
            <v-select
              v-else-if="f.type === 'multiple-enum'"
              label="alias"
              :reduce="
                (option) =>
                  f.valueFormatter
                    ? f.valueFormatter(option)
                    : { id: option.id, key: option.key, alias: option.alias }
              "
              :options="
                (f.extra.optionsFunction
                  ? f.extra.optionsFunction(sidebarConfig.model)
                  : f.extra.options
                )
                  .map((x) => ({
                    id: x.id,
                    key: x.key,
                    alias: $t(`enums.${f.extra.enumType}.${x.key}`),
                  }))
                  .sort((a, b) => {
                    return f.extra.customSort
                      ? f.extra.customSort(a, b)
                      : a.alias.localeCompare(b.alias);
                  })
              "
              :getOptionLabel="(o) => getOptionLabel(o, f)"
              :clearable="!f.validation || f.validation.indexOf('required') < 0"
              multiple
              v-model="sidebarConfig.model[f.attribute]"
              class="mb-4 md:mb-0"
              :v-validate="f.validation"
              :name="f.attribute"
              :data-vv-as="
                $t(`${sidebarConfig.collectionName}.fields.${f.attribute}`)
              "
            />
            <v-select
              v-else-if="f.type === 'select'"
              label="alias"
              :reduce="
                (option) =>
                  f.valueFormatter
                    ? f.valueFormatter(option)
                    : { id: option.id, alias: option.alias }
              "
              :options="
                f.extra.optionsFunction
                  ? f.extra.optionsFunction(sidebarConfig.model)
                  : f.extra.options
              "
              :getOptionLabel="(o) => getOptionLabel(o, f)"
              :clearable="!f.validation || f.validation.indexOf('required') < 0"
              v-model="sidebarConfig.model[f.attribute]"
              class="mb-4 md:mb-0"
              :v-validate="f.validation"
              :name="f.attribute"
              :filterBy="selectFilter"
              :data-vv-as="
                $t(`${sidebarConfig.collectionName}.fields.${f.attribute}`)
              "
            />
            <v-select
              v-else-if="f.type === 'multiple-select'"
              label="alias"
              :reduce="
                (option) =>
                  f.valueFormatter
                    ? f.valueFormatter(option)
                    : { id: option.id, alias: option.alias }
              "
              :options="
                f.extra.optionsFunction
                  ? f.extra.optionsFunction(sidebarConfig.model)
                  : f.extra.options
              "
              :getOptionLabel="(o) => getOptionLabel(o, f)"
              :clearable="!f.validation || f.validation.indexOf('required') < 0"
              multiple
              v-model="sidebarConfig.model[f.attribute]"
              class="mb-4 md:mb-0"
              :v-validate="f.validation"
              :name="f.attribute"
              :data-vv-as="
                $t(`${sidebarConfig.collectionName}.fields.${f.attribute}`)
              "
            />
            <v-select
              v-else-if="f.type === 'boolean'"
              :reduce="(option) => option.value"
              :options="[
                { label: $t('boolean.yes'), value: true },
                { label: $t('boolean.no'), value: false },
              ]"
              :clearable="!f.validation || f.validation.indexOf('required') < 0"
              v-model="sidebarConfig.model[f.attribute]"
              class="mb-4 md:mb-0"
              :v-validate="f.validation"
              :name="f.attribute"
              :data-vv-as="
                $t(`${sidebarConfig.collectionName}.fields.${f.attribute}`)
              "
            />

            <span class="text-danger text-sm" v-show="errors.has(f.attribute)">
              {{ errors.first(f.attribute) }}
            </span>
          </div>
        </div>
      </VuePerfectScrollbar>

      <div class="flex flex-wrap items-center p-6" slot="footer">
        <vs-button
          class="mr-6"
          @click="saveSidebarConfig()"
          :disabled="!sidebarConfigChanged"
        >
          {{ $t("buttons.save") }}
        </vs-button>
        <vs-button type="border" color="danger" @click="sidebarConfig = null">
          {{ $t("buttons.cancel") }}
        </vs-button>
      </div>
    </vs-sidebar>

    <div class="vx-row page-filters" v-if="!!filters && filters.length > 0">
      <transition
        name="fade"
        v-for="(f, p) in filters"
        v-bind:key="`filter_${p}`"
      >
        <div
          class="vx-col w-full"
          :class="`md:w-${f.size} sm:w-${f.mobileSize} ${f.extraClasses || ''}`"
        >
          <vs-input
            class="mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
            v-model="searchQuery"
            @input="updateSearchQuery"
            :placeholder="$t('messages.search')"
            v-if="f.search"
          />
          <vs-button
            color="primary"
            type="filled"
            class="mb-5 ml-auto"
            @click="() => toggleSidebar(f.sidebarConfig)"
            :to="f.to"
            v-else-if="f.sidebar"
          >
            {{ $t(f.i18n) }}
          </vs-button>
          <vs-button
            color="primary"
            type="filled"
            class="mb-5 ml-auto"
            :to="f.to"
            v-else-if="f.button"
          >
            {{ $t(f.i18n) }}
          </vs-button>
          <vs-button
            :color="f.color || 'primary'"
            type="filled"
            class="mb-5 ml-auto"
            v-else-if="f.onClickButton"
            @click="
              () => {
                f.onClick();
              }
            "
          >
            {{ $t(f.i18n) }}
          </vs-button>
          <v-select
            v-else-if="f.select"
            :options="f.options()"
            :label="f.label"
            @input="f.onChange"
            :clearable="f.clearable"
            :resetOnOptionsChange="f.resetOnOptionsChange"
            class="mb-5"
            :placeholder="$t(f.placeholder)"
            :disabled="f.disabled"
            :value="f.value"
          />
          <v-select
            v-else-if="f.enum"
            :options="
              f.options().map((x) => ({
                id: x.id,
                alias: $t(`enums.${f.enumType}.${x.key}`),
              }))
            "
            :label="f.label"
            @input="f.onChange"
            :clearable="f.clearable"
            :resetOnOptionsChange="f.resetOnOptionsChange"
            class="mb-5"
            :placeholder="$t(f.placeholder)"
            :disabled="f.disabled"
            :value="f.value"
          />

          <slot v-else-if="f.custom" :name="f.template" v-bind="f.data" />
        </div>
      </transition>
    </div>

    <div class="vx-card p-6">
      <div class="custom-loading" v-if="!gridData">
        <div class="loading">
          <div class="effect-1 effects"></div>
          <div class="effect-2 effects"></div>
          <div class="effect-3 effects"></div>
        </div>
      </div>

      <ag-grid-vue
        v-else
        ref="agGridTable"
        :components="gridConfig.components"
        :gridOptions="gridConfig.gridOptions"
        class="ag-theme-material w-100 ag-grid-table"
        :class="customListClass"
        :columnDefs="columnDefs"
        :defaultColDef="gridConfig.defaultColDef"
        :rowData="gridData"
        @rowClicked="gridConfig.onRowClicked"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="true"
        :paginationPageSize="25"
        :paginationAutoPageSize="false"
        :suppressPaginationPanel="false"
        :enableRtl="$vs.rtl"
        :suppressCellSelection="true"
        domLayout="autoHeight"
        :localeText="$t('grid')"
      />
    </div>
  </div>
</template>

<script>
import { diff, applyChange } from "deep-diff";

import { db } from "@/firebase/firebaseConfig";
import firebase from "firebase/app";

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import vSelect from "vue-select";
import { Sketch } from "vue-color";

import CellRendererInput from "@/components/penal-retail-helpers/CellRendererInput.vue";
import CellRendererRemove from "@/components/penal-retail-helpers/CellRendererRemove.vue";
import CellRendererButton from "@/components/penal-retail-helpers/CellRendererButton.vue";
import CellRendererStatus from "@/views/main/cases/cases-cells/CellRendererStatus.vue";
import CellRendererCivilStatus from "@/views/civil/cases/helpers/CellRendererCivilStatus.vue";

export default {
  name: "ListPage",
  components: {
    VuePerfectScrollbar,
    AgGridVue,
    vSelect,
    "sketch-picker": Sketch,
    CellRendererStatus,
    CellRendererCivilStatus,
    CellRendererRemove,
    CellRendererButton,
    CellRendererInput,
  },
  props: {
    customListClass: String,
    gridData: Array,
    filters: Array,
    gridConfig: Object,
  },
  created() {
    if (this.gridData && !this.gridApi) {
      setTimeout(() => {
        this.gridApi = this.gridConfig.gridOptions.api;
        this.gridApi.sizeColumnsToFit();

        window.onresize = () => {
          if (this.gridData) {
            this.gridApi.sizeColumnsToFit();
          }
        };
      }, 0);
    }
  },
  data() {
    return {
      sidebarConfig: null,
      gridApi: null,
      searchQuery: "",
    };
  },
  watch: {
    gridData() {
      if (this.gridData && !this.gridApi) {
        setTimeout(() => {
          this.gridApi = this.gridConfig.gridOptions.api;
          this.gridApi.sizeColumnsToFit();
        }, 0);
      }
    },
    "sidebarConfig.originalData"() {
      if (this.sidebarConfig) {
        //Actualizamos nuestro modelo para que tenga una copia de los datos de la base de datos
        this.sidebarConfig.model = Object.assign(
          {},
          this.sidebarConfig.originalData
        );

        //Aplicamos sobre nuestro modelo todas aquellas modificaciones que podamos tener pendiente de guardar.
        Object.keys(this.sidebarConfig.modifications).forEach((m) => {
          this.sidebarConfig.model[m] = this.sidebarConfig.modifications[m];
        });
      }
    },
    "sidebarConfig.model": {
      deep: true,
      handler() {
        if (this.sidebarConfig) {
          this.sidebarConfig.modifications = {};
          (
            diff(this.sidebarConfig.originalData, this.sidebarConfig.model) ||
            []
          ).forEach((d) => {
            applyChange(
              this.sidebarConfig.modifications,
              this.sidebarConfig.model,
              d
            );
          });
        }
      },
    },
  },
  computed: {
    columnDefs() {
      let columnDefs = this.gridConfig.columnDefs.map((x) => {
        if (x.headerKey) {
          x.headerName = this.$t(x.headerKey);
          delete x.headerKey;
        }
        return x;
      });

      return columnDefs;
    },
    sidebarConfigChanged() {
      return diff(this.sidebarConfig.originalData, this.sidebarConfig.model);
    },
  },
  methods: {
    updateColor(e, f) {
      this.sidebarConfig.model[f.attribute] = e.hex;

      this.sidebarConfig.model = Object.assign({}, this.sidebarConfig.model);
    },
    getOptionLabel(option, f) {
      if (option) {
        let options = f.extra.optionsFunction
          ? f.extra.optionsFunction(this.sidebarConfig.model)
          : f.extra.options;

        if (["enum", "multiple-enum"].includes(f.type)) {
          options = options.map((x) => ({
            id: x.id,
            key: x.key,
            alias: this.$t(`enums.${f.extra.enumType}.${x.key}`),
          }));
        }

        let optionId = option.id ? option.id : option;
        let found = options.find((v) => v.id === optionId) || {};
        return found.alias || null;
      } else {
        return null;
      }
    },
    selectFilter(option, label, search) {
      const removeDiacritics = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };
      return (
        removeDiacritics(label).toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    async toggleSidebar(config) {
      this.sidebarConfig = config;
      if (config) {
        this.sidebarConfig.modifications = {};
        this.sidebarConfig.model = {};
        this.sidebarConfig.originalData = config.id
          ? await db.collection(config.collectionName).doc(config.id)
          : {};
        this.sidebarConfig = Object.assign({}, this.sidebarConfig);
      }
    },
    saveSidebarConfig() {
      if (this.sidebarConfig) {
        this.$validator.validateAll().then(async (result) => {
          let prevalidateErrors = await this.sidebarConfig.prevalidate(
            this.sidebarConfig.model,
            db.collection(
              this.sidebarConfig.realCollectionName ||
                this.sidebarConfig.collectionName
            ),
            this.errors
          );
          if (result && !prevalidateErrors) {
            try {
              if (this.id) {
                await db
                  .collection(
                    this.sidebarConfig.realCollectionName ||
                      this.sidebarConfig.collectionName
                  )
                  .doc(this.sidebarConfig.id)
                  .update(
                    Object.assign(this.modifications, {
                      updatedAt:
                        firebase.firestore.FieldValue.serverTimestamp(),
                      updatedBy: {
                        email: this.$store.state.AppActiveUser.email,
                        uid: this.$store.state.AppActiveUser.uid,
                      },
                    })
                  );
                this.$vs.notify({
                  title: this.$t("messages.success.docUpdated"),
                  color: "success",
                });
                this.sidebarConfig = null;
              } else {
                let createModel = Object.assign(this.sidebarConfig.model, {
                  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                  createdBy: {
                    email: this.$store.state.AppActiveUser.email,
                    uid: this.$store.state.AppActiveUser.uid,
                  },
                  deleted: false,
                });
                let docRef;
                if (this.sidebarConfig.customCreate) {
                  docRef = await this.sidebarConfig.customCreate(createModel);
                } else {
                  docRef = await db
                    .collection(
                      this.sidebarConfig.realCollectionName ||
                        this.sidebarConfig.collectionName
                    )
                    .add(createModel);
                }
                if (this.sidebarConfig.afterCreate) {
                  this.sidebarConfig.afterCreate(docRef.id);
                }
                this.$vs.notify({
                  title: this.$t("messages.success.docCreated"),
                  color: "success",
                });
                this.sidebarConfig = null;
              }
            } catch (error) {
              console.error(
                this.sidebarConfig.collectionName,
                "Error adding or editing document: ",
                this.sidebarConfig.id,
                error
              );
            }
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.penal-reatail-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.custom-loading {
  position: relative;
  width: 100%;
  height: 110px;

  .loading {
    top: calc(50% - 35px);
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
<style lang="scss">
.page-filters {
  .vs__selected-options {
    overflow: hidden;
    width: calc(100% - 31px);
    min-height: 30px;

    .vs__selected {
      white-space: nowrap;
    }

    .vs__search {
      position: absolute;
      width: 100%;
    }
  }
}
</style>

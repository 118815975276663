<template>
  <div class="flex items-center">
    <vs-button :color="params.colDef.color" @click="doClick">{{params.colDef.title}}</vs-button>
  </div>
</template>

<script>
    export default {
        name: 'CellRendererRemove',
        methods: {
          doClick() {
            this.params.colDef.onClick(this.params.value);
          }
        }
    }
</script>

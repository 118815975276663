<template>
  <div class="flex items-center">
    <vs-button
      color="danger"
      @click="doRemove"
      icon-pack="feather"
      icon="icon-trash"
      :title="$t('buttons.remove')"
    >
      {{ $t("buttons.remove") }}
    </vs-button>
  </div>
</template>

<script>
export default {
  name: "CellRendererRemove",
  methods: {
    doRemove() {
      this.params.colDef.onRemove(this.params.value);
    },
  },
};
</script>

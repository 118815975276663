<template>
  <vs-input v-if="params.colDef.penalRetail.type === 'text'" class="w-full" :value="params.value" @change="onChange" />
  <flat-pickr
      v-else-if="params.colDef.penalRetail.type === 'datetime'"
      v-model="formattedDateValue"
      :config="params.colDef.penalRetail.config"
      class="flatpickr-input"
      :name="params.field"
      data-vv-as="Fecha"
      v-validate=""
  />
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

    export default {
      components: {
        flatPickr
      },
      name: 'CellRendererInput',
      computed: {
        formattedDateValue() {
          let value = null;
          if (this.params) {
            value = this.params.value;
            if (this.params.value && (this.params.colDef.penalRetail.type === 'datetime')) {
              value = this.$moment(this.params.value.toDate()).format("DD-MM-YYYY HH:mm") || null;
            }
          }
          return value;
        }
      },
      methods: {
        onChange(e) {
          this.params.colDef.onChange(this.params.data.id, e.target.value);
        }
      }
    }
</script>
